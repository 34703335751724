.vision{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    padding: 3rem 0;
}

.vision-img{
    width: 35vw;
}

.vision-content{
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    max-width: 500px;
}

.vision-title{
    font-size: var(--h3-font-size);
}

.vision-subpoint{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.vision-icon i{
    font-size: 2rem;
    color: #ff624d;
}

.vision-subpoint-description h3{
    font-size: var(--h3-font-size);
}

.vision-start-button button{
    background-color: #ff725e;
    color: #ffffff;
}

.vision-start-button button:hover{
    background-color: #fa442c;
    color: rgb(255, 255, 255);
}

/* ================ BREAKPOINS ===============*/
/* For large devices  */
@media screen and (max-width: 992px) {
    .vision{
        grid-template-columns: repeat(1,1fr);
        padding: 1 1rem;
    }

    .vision-content{
        max-width: 700px;
    }

    .vision-img{
        width: 45vw;
    }

    .vision-start-button{
        text-align: center;
        margin-top: 1rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .vision-content{
        max-width: 500px;
        margin: 0 auto;
    }

    .vision-img{
        width: 70vw;
    }

    
  }
  
  @media screen and (max-width: 576px) {
    .vision{
        /* grid-template-columns: repeat(1,1fr); */
        padding: 2rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    
  }