/* ================ Hero section ===============*/
#home {
    width: 100%;
}

.hero,
.hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hero-content {
    height: 50vh;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 4rem;
}

.hero-title {
    font-size: var(--big-font-size);
}

.hero-subtitle {
    max-width: 650px;
    margin-top: 0.5rem;
}

.hero-buttons {
    margin-top: 3rem;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.hero-button {
    font-size: var(--normal-font-size);
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.get-started {
    color: #19e2a5;
    background-color: #d5f5ec;
}

.learn-more {
    color: #ff6b6b;
    background-color: #fae3e3;
}

.get-started:hover {
    color: whitesmoke;
    background-color: #02cb8f;
    ;
}

.learn-more:hover {
    color: whitesmoke;
    background-color: #ff3e3e;
}

.hero-img {
    width: 35vw;
}

/* ================ ServiesFT ===============*/
.serviceFT {
    /* background-color: #fae3e3; */
    width: 100%;
    padding-bottom: 5rem;
}



.service-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.service-card {
    width: 350px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: .5px solid rgba(0, 0, 0, 0.2);
}

.service-card:hover {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.service-card img {
    width: 200px;
    height: auto;
}

.service-card p {
    font-size: var(--smaller-font-size);
}

/* .service-card span {
    font-size: var(--normal-font-size);
} */



.services-button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.services-button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.services-button:hover .services-button-icon {
    transform: translateX(00.25rem);
}

.services-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.services-modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.services-modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.services-modal-title,
.services-modal-description {
    text-align: center;
}

.services-modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.services-modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.services-modal-services {
    row-gap: 0.75rem;
}

.services-modal-service {
    display: flex;
    align-items: center;
    column-gap: .5rem;
    margin-left: 2rem;
}

.services-modal-icon {
    color: var(--title-color);
    font-size: 1.1rem;
}

.services-modal-info {
    font-size: var(--small-font-size);
}

/* Active Modal */

.active-modal {
    opacity: 1;
    visibility: visible;
}

/* ================ QUOTE ===============*/
.quote {
    width: 100%;
    display: grid;
    place-items: center;
    /* margin: 4rem 0; */
    color: black;
    background-image: linear-gradient(
    118deg,
    #ff715ec7,
    #fae3e3a0
    ),
    url("../../assest/quote.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.quote-contents {
    max-width: 1050px;
    max-height: 50vh;
    display: flex;
    flex-flow: column nowrap;
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
}
.quote-contents i{
    margin-bottom: 3rem;
    font-size: 4rem;
}

.quote-contents div{
    font-weight: 600;
    margin-bottom: 4rem;
}


/* ================ BREAKPOINS ===============*/
/* For large devices  */

@media screen and (max-width: 992px) {
    .hero-title {
        font-size: var(--h1-font-size);
    }

    .quote-contents {
        padding: 0 4rem;
        font-size: 2rem;
    }

}

/* For medium devices */
@media screen and (max-width: 768px) {
    .hero-title {
        font-size: var(--h1-font-size);
    }

    .hero-content {
        height: 60vh;
    }

    .hero-img {
        width: 100vw;
    }

    .quote-contents {
        padding: 0 2rem;
        font-size: 1.5rem;
    }

    .quote-contents i{
        margin-bottom: 2rem;
        font-size: 3rem;
    }

}

@media screen and (max-width: 576px) {
    .service-cards {
        margin-right: 1.2rem;
    }

    .hero-title {
        font-size: var(--h1-font-size);
        margin-bottom: 1rem;
    }

    .service-card {
        width: 340px;
        height: 350px;
    }

    .service-card p {
        font-size: var(--smaller-font-size);
    }

    .services-modal-content {
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .services-modal-description {
        padding: 0;
    }

    .quote-contents {
        padding: 0 1rem;
        font-size: 1.2rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {}