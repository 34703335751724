/* ================ CONTACT CARDS ===============*/
.contact {
    padding-bottom: 4rem;
}

.contact-links {
    max-width: 1200px;
    margin: 4rem auto;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
}

.contact-link-card {
    width: 350px;
    border: 1px solid rgb(197, 197, 197);
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    border-radius: 10px;
}

.contact-link-card:hover {
    border: 1px solid black;
    background-color: #fef8f5;
    transition: .3s ease-in-out;
    scale: 1.02;
}

.contact-link-icon {
    font-size: 4rem;
}



/* ================ CONTACT FORM ===============*/

.contact-form {
    max-width: 1200px;
    margin: auto;
    margin-bottom: 5rem;
}

.contact-form-cantainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 2rem;
    margin-top: 4rem;
}

.form-fild-title {
    font-size: var(--h2-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    border-bottom: 2px solid black;
    margin: 2rem 0;
}

.form-fild {
    display: flex;
    flex-flow: column nowrap;
    gap: .5rem;
}

.form-textarea,
.form-fild-title,
.collage-name {
    grid-column: span 2;
}

.form-fild label {
    color: black;
}

.form-fild sup {
    color: red;
}

.form-fild input,
textarea{
    padding: 10px;
}

select{
    padding: 13px;
    font-size: var(--normal-font-size);
}

.form-fild textarea {
    resize: vertical;
}

.option-fild span{
    padding: 10px;
}

::placeholder,.optional-text{
    color: rgb(149, 149, 149);
}

.optional-text{
    font-size: var(--smaller-font-size);
}

.form-fild button {
    margin-right: auto;
    background-color: #ff6d1f;
}

.form-fild button:hover {
    background-color: #ff5900;
    color: black;
}

/* ================ BREAKPOINS ===============*/
/* For large devices  */
@media screen and (max-width: 992px) {
    .contact-form {
        margin: 5rem 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .contact-form-cantainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .form-textarea,
    .form-fild-title,
    .collage-name {
        grid-column: span 1;
    }
}

@media screen and (max-width: 576px) {
    .contact-form {
        max-width: 500px;
        margin: 3rem 1rem;
    }

    .contact-form-cantainer {
        grid-template-columns: repeat(1, max-width(400px));
    }

    input,
    textarea {
        width: 83vw;
        /* margin-right: 1rem; */
    }
}

/* For small devices */
@media screen and (max-width: 450px) {
    /* .form-fild {
        max-width: 400px;
    } */
}