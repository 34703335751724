/* =============== GOOGLE FONTS ===============  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* =============== VARIABLES CSS ===============  */
:root {
  --header-height: 3rem;

  /* ========== Colors ========== */
  /* color mode HSL(hue,saturation,lightness) */

  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /* ========== Font ans typogarphy ========== */
  --body-font: 'Poppins', sans-serif;

  --big-font-size: 3rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* =========== Font Weight ========== */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* ============ margenes bottom =========== */

  --mb--0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* ========== z index ========== */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

}

/* responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* ================ BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: #fff;
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* ================ REUSABLE CSS CLASSES ===============*/
.section {
  padding: 0 4rem;
}

.section-text {
  text-align: center;
  padding: 0 1rem;
  padding-top: 4rem;
}

.section-text h2 {
  font-size: var(--h2-font-size);
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section-subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section-title,
.section-subtitle {
  text-align: center;
}

.bg-color{
  background-color: #fef8f5;
}
/* ================ LAYOUT ===============*/
.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* ================ BUTTONS ===============*/
.button {
  display: inline-block;
  font-weight: var(--font-medium);
  font-size: var(--normal-font-size);
  color: var(--title-color-dark);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.button:hover {
  background-color: var(--title-color-dark);
  color: var(--container-color);
  transition: .3s ease-in-out;
}

.button-icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/* ================ BREAKPOINS ===============*/
/* For large devices  */
@media screen and (max-width: 992px) {
  /* .container{
     margin-left: 2rem;
    margin-right: 2rem; 
  } */

  .button {
    padding: .7rem 1.75rem;
  }

  .button_icon {
    width: 22px;
    height: 22px;
  }

  .section {
    padding: 0;
  }

}

/* For medium devices */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 0 0;
  }

  
  .section-sbutitle {
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px) {}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem
  }

  /* 
  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  } */
}